import React, { useEffect, useState, useRef } from "react";
import useWebSocket from "react-use-websocket";
import { Tab, Nav, Button, Modal, Accordion } from "react-bootstrap";
import { Link } from "react-router-dom";
import loadable from "@loadable/component";
import pMinDelay from "p-min-delay";

//import IntradayChart from './IntradayChart';
import OrderTab from "./Future/OrderTab";
import TradeTab from "./Future/TradeTab";
import Index5CoinChart from "../Dashboard/Index5/Index5CoinChart";

// const widgetChart = [
//   { id: 1, name: "Starting Capital", price: "$65,123", bgcolor: "bg-warning" },
//   { id: 2, name: "Profit", price: "$40,742", bgcolor: "bg-pink" },
//   { id: 3, name: "PNL", price: "$71,321", bgcolor: "bg-primary" },
// ];

const WidgetChartIndex3 = loadable(() =>
  pMinDelay(import("../Dashboard/Index3/WidgetChartIndex3"), 1000)
);

const TradeAsYouGo = () => {
  const symbol = "BTCUSDT";
  const streamUrl = `wss://stream.binance.com:9443/ws/${symbol.toLowerCase()}@trade`;

  const [tabeData, setTabeData] = useState([
    {
      pair: "2022/11/20",
      side: "BITCOIN",
      size: "BUY",
      price: "$20000",
      entry: "$162,700",
      status: "open",
      pnl: "trade",
    },
    {
      pair: "2022/11/22",
      side: "BTC",
      size: "SELL",
      price: "$21000",
      entry: "$320,800",
      status: "close",
      pnl: "trade",
    },
    {
      pair: "2022/12/10",
      side: "BTCUSD",
      size: "SELL",
      price: "$23000",
      entry: "$170,750",
      status: "open",
      pnl: "trade",
    },
    {
      pair: "2022/12/25",
      side: "INR",
      size: "BUY",
      price: "$27000",
      entry: "$86,000",
      status: "open",
      pnl: "trade",
    },
    {
      pair: "2022/12/22",
      side: "SEN",
      size: "SELL",
      price: "$13000",
      entry: "$372,000",
      status: "close",
      pnl: "trade",
    },
    {
      pair: "2022/12/30",
      side: "USD",
      size: "BUY",
      price: "$16000",
      entry: "$433,060",
      status: "open",
      pnl: "trade",
    },
    {
      pair: "2022/12/30",
      side: "USD",
      size: "BUY",
      price: "$16000",
      entry: "$433,060",
      status: "open",
      pnl: "trade",
    },
    {
      pair: "2022/12/30",
      side: "USD",
      size: "BUY",
      price: "$16000",
      entry: "$433,060",
      status: "open",
      pnl: "trade",
    },
    {
      pair: "2022/12/30",
      side: "USD",
      size: "BUY",
      price: "$16000",
      entry: "$433,060",
      status: "open",
      pnl: "trade",
    },
    {
      pair: "2022/12/30",
      side: "USD",
      size: "BUY",
      price: "$16000",
      entry: "$433,060",
      status: "open",
      pnl: "trade",
    },
    {
      pair: "2022/12/30",
      side: "USD",
      size: "BUY",
      price: "$16000",
      entry: "$433,060",
      status: "open",
      pnl: "trade",
    },
    {
      pair: "2022/12/30",
      side: "USD",
      size: "BUY",
      price: "$16000",
      entry: "$433,060",
      status: "open",
      pnl: "trade",
    },
    {
      pair: "2022/12/30",
      side: "USD",
      size: "BUY",
      price: "$16000",
      entry: "$433,060",
      status: "open",
      pnl: "trade",
    },
    {
      pair: "2022/12/30",
      side: "USD",
      size: "BUY",
      price: "$16000",
      entry: "$433,060",
      status: "open",
      pnl: "trade",
    },
  ]);

  const [aiTradeData, setAiTradeData] = useState([
    {
      id: 1,
      name: "Starting Capital",
      price: "$65,123",
      bgcolor: "bg-warning",
      data: [10, -20, 50, 250, 50, 300, 100, 200, 30],
    },
    {
      id: 2,
      name: "Profit",
      price: "$40,742",
      bgcolor: "bg-pink",
      data: [800, 310, 50, 250, 50, 1000, 100, 200],
    },
    {
      id: 3,
      name: "PNL",
      price: "$71,321",
      bgcolor: "bg-primary",
      data: [200, 310, 50, 250, 50, 300, 100, 800],
    },
  ]);

  // y-axis formart = [Open, High, Low, Close]
  const [barchartData, setBarchartData] = useState({
    pair: "BTC/USDT",
    data: [],
  });

  const [configure, setConfigure] = useState(false);
  const [configured, setConfigured] = useState(false);
  const [binanceApiKey, setBinanceApiKey] = useState("");
  const [binanceApiSecret, setBinanceApiSecret] = useState("");
  const [modalCentered, setModalCentered] = useState(false);
  const [test, settest] = useState(0);

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 6;
  const totalItems = tabeData.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const chartRef = useRef(null); // Ref for the chart section

  const getCurrentPageData = () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return tabeData.slice(startIndex, endIndex);
  };

  const goToPage = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // const { lastJsonMessage } = useWebSocket(streamUrl, {
  //   onOpen: () => console.log("Connected to the stream!"),
  //   onClose: () => console.log("Disconnected from the stream!"),
  //   shouldReconnect: (closeEvent) => true, // Will attempt to reconnect on all disconnects
  // });

  // Function to convert the historical data into the desired format
  const formatHistoricalData = (data) => {
    return data.map((item) => ({
      x: new Date(item[0]),
      y: [
        parseFloat(item[1]),
        parseFloat(item[2]),
        parseFloat(item[3]),
        parseFloat(item[4]),
      ],
    }));
  };

  const processTradeData = (trade) => {
    setBarchartData((currentData) => {
      const newTradeTime = new Date(trade.E);
      const newTradePrice = parseFloat(trade.p);
      let lastEntry = currentData.data[currentData.data.length - 1];
      let updatedData;

      if (
        !lastEntry ||
        new Date(lastEntry.x).getMinutes() !== newTradeTime.getMinutes()
      ) {
        // Start a new minute entry
        updatedData = {
          ...currentData,
          data: [
            ...currentData.data,
            {
              x: new Date(newTradeTime.setSeconds(0, 0)),
              y: [newTradePrice, newTradePrice, newTradePrice, newTradePrice],
            },
          ],
        };
      } else {
        // Update the last entry
        lastEntry.y = [
          lastEntry.y[0], // Open remains the same
          Math.max(lastEntry.y[1], newTradePrice), // High
          Math.min(lastEntry.y[2], newTradePrice), // Low
          newTradePrice, // Close is the latest price
        ];
        updatedData = currentData;
      }

      // Ensure that the length of updatedData does not exceed 200 elements
      if (updatedData.length > 100) {
        return updatedData.slice(updatedData.length - 100);
      }

      return updatedData;
    });
  };

  const handleSave = () => {
    localStorage.setItem("tradeAsYouGoConfigured", "true");
    localStorage.setItem("binanceApiKey", binanceApiKey);
    localStorage.setItem("binanceApiSecret", binanceApiSecret);

    // Update state to reflect the changes
    setConfigured(true);
    setConfigure(false);
  };

  useEffect(() => {
    // Fetch historical data
    const fetchHistoricalData = async () => {
      try {
        const interval = "1m"; // 1-minute intervals
        const limit = 100; // Number of minutes in 24 hours

        const url = `https://api.binance.com/api/v3/klines?symbol=${symbol}&interval=${interval}&limit=${limit}`;

        const response = await fetch(url);

        const data = await response.json();

        setBarchartData({ pair: "BTC/USDT", data: formatHistoricalData(data) });
      } catch (error) {
        console.error("Error fetching historical data:", error);
      }
    };

    fetchHistoricalData();
  }, [symbol]);

  // Check local storage on page load
  useEffect(() => {
    const isConfigured = localStorage.getItem("tradeAsYouGoConfigured");
    const apiKey = localStorage.getItem("binanceApiKey");
    const apiSecret = localStorage.getItem("binanceApiSecret");

    if (isConfigured === "true" && apiKey && apiSecret) {
      setConfigured(true);
      setBinanceApiKey(apiKey);
      setBinanceApiSecret(apiSecret);
      console.log("chartRef", chartRef);
      // Check if the chartRef.current is available and then scroll
      const timer = setTimeout(() => {
        if (chartRef.current) {
          chartRef.current.scrollIntoView({ behavior: "smooth" });
        }
      }, 100); // A 100ms delay

      return () => clearTimeout(timer); // Clean up the timer
    } else {
      setConfigured(false);
    }
  }, [configured]);

  // useEffect(() => {
  //   if (lastJsonMessage) {
  //     processTradeData(lastJsonMessage);
  //   }
  // }, [lastJsonMessage]);

  const [data, setData] = useState(
    document.querySelectorAll("#status_wrapper tbody tr")
  );

  // use effect
  useEffect(() => {
    setData(document.querySelectorAll("#status_wrapper tbody tr"));
    //chackboxFun();
  }, [test]);

  //   Pagination
  const sort = 6;
  const activePag = useRef(0);

  // Active data
  const chageData = (frist, sec) => {
    for (var i = 0; i < data.length; ++i) {
      if (i >= frist && i < sec) {
        data[i].classList.remove("d-none");
      } else {
        data[i].classList.add("d-none");
      }
    }
  };

  // // Active pagginarion
  // activePag.current === 0 && chageData(0, sort);

  // // paggination
  // let paggination = Array(Math.ceil(data.length / sort))
  //   .fill()
  //   .map((_, i) => i + 1);

  // console.log("paggination", paggination);

  // // Active paggination & chage data
  // const onClick = (i) => {
  //   activePag.current = i;
  //   chageData(activePag.current * sort, (activePag.current + 1) * sort);
  //   settest(i);
  // };

  return (
    <>
      <div className="row">
        {(configured || configure) && (
          <div className="col-xl-12">
            <div className="card p-4">
              <h4 className="card-title">Disclaimer</h4>

              <ul>
                <li className="pb-2">
                  <strong>High-Risk Activity:</strong> Trading in financial
                  markets involves a high level of risk. It may not be suitable
                  for all investors, and you should carefully consider your
                  financial situation and risk tolerance before engaging in any
                  trading activities.
                </li>

                <li className="pb-2">
                  <strong>No Guaranteed Profits:</strong> PlowGPT provides
                  trading services based on algorithms, but there is no
                  guarantee of profits. Past performance is not indicative of
                  future results, and market conditions can change rapidly.
                </li>

                <li className="pb-2">
                  <strong>Responsibility for Decisions:</strong> You are solely
                  responsible for your trading decisions. PlowGPT does not
                  provide financial advice, and any decision to trade is made at
                  your own discretion.
                </li>

                <li className="pb-2">
                  <strong>Security of Credentials:</strong> When using the App,
                  you are responsible for the security of your login
                  credentials, including API keys. PlowGPT does not store API
                  keys on its servers. Ensure that your browser is secure and
                  connected to a fast internet connection for trade execution.
                </li>

                <li className="pb-2">
                  <strong>Risk Management:</strong> While the App aims to manage
                  risks effectively, there is no guarantee against losses. Never
                  trade with funds you cannot afford to lose.
                </li>

                <li className="pb-2">
                  <strong>Multiple Positions Warning:</strong> While PlowGPT has
                  open positions, refrain from transferring or opening
                  additional positions on futures in the same account, whether
                  on the same or different pairs.
                </li>

                <li className="pb-2">
                  <strong>Service Fee:</strong> PlowGPT charges a service fee of
                  15% on every won trade. This fee structure is designed to
                  avoid upfront costs, but it is essential to be aware of this
                  charge.
                </li>

                <li className="pb-2">
                  <strong>Liability Disclaimer:</strong> PlowGPT indemnifies
                  itself from any losses in all cases. Trading involves inherent
                  risks, and losses may occur. Use the App with caution and
                  allocate only a small portion of your portfolio.
                </li>

                <li className="pb-2">
                  <strong>Binance API Access:</strong> The App requires access
                  to your Binance account with specific permissions, including
                  futures, spot, and withdrawals. Ensure that you grant the
                  necessary permissions for the App to function correctly.
                </li>
              </ul>

              <p>
                By accepting these terms, you acknowledge that trading involves
                risks, and you have carefully read and understood the terms and
                conditions outlined in this disclaimer. PlowGPT is not liable
                for any financial losses or damages incurred as a result of
                using the App.
              </p>
            </div>
          </div>
        )}
        {configured || configure ? (
          <div className="col-xl-12">
            <div className="card">
              <div className="card-header border-0 pb-0">
                <h4 className="heading">Settings</h4>
              </div>
              <div className="card-body pt-4">
                <form>
                  <div className="row g-3 mb-3">
                    <div className="col-xl-12">
                      <label className="form-label">Binance API KEY</label>
                      <div className="input-group">
                        <input
                          disabled={!configured && !configure}
                          value={binanceApiKey}
                          onChange={(e) => setBinanceApiKey(e.target.value)}
                          type="text"
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="col-xl-12">
                      <label className="form-label">Binance API Secret</label>
                      <input
                        disabled={!configured && !configure}
                        type="text"
                        className="form-control"
                        value={binanceApiSecret}
                        onChange={(e) => setBinanceApiSecret(e.target.value)}
                      />
                    </div>
                  </div>
                  <button
                    type="button"
                    className="btn flex-fill btn-success py-2 fs-5 text-uppercase px-5"
                    onClick={handleSave}
                  >
                    Save
                  </button>
                </form>
              </div>
            </div>
          </div>
        ) : (
          <div className="row justify-content-center h-100 align-items-center ">
            <div className="col-md-7">
              <div className="form-input-content text-center">
                <div>
                  <button
                    type="button"
                    onClick={() => setModalCentered(true)}
                    className="btn flex-fill btn-primary py-2 fs-5 text-uppercase px-5"
                  >
                    Configure
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
        {configured && (
          <>
            <div className="col-xl-12">
              {/* Here for chart */}
              <div className="row">
                {aiTradeData.map((item, ind) => (
                  <div className="col-xl-4 col-lg-6 col-sm-6" key={ind}>
                    <div className={`card card-box ${item.bgcolor}`}>
                      <div className="card-header border-0 pb-0">
                        <div className="chart-num-days">
                          <p>{item.name}</p>
                          <h2 className="count-num text-white">{item.price}</h2>
                        </div>
                        {item.id === 1 ? (
                          <svg
                            width="50"
                            height="50"
                            viewBox="0 0 137 137"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M92.9644 53.8221C92.9599 48.4197 88.5804 44.0417 83.1795 44.0357H53.822V63.6069H83.1795C88.5804 63.6024 92.9599 59.2229 92.9644 53.8221Z"
                              fill="#FFF"
                            />
                            <path
                              d="M53.822 92.9645H83.1795C88.5834 92.9645 92.9644 88.5835 92.9644 83.1796C92.9644 77.7743 88.5834 73.3933 83.1795 73.3933H53.822V92.9645Z"
                              fill="#FFF"
                            />
                            <path
                              d="M68.5001 9.15527e-05C30.6687 9.15527e-05 0.00012207 30.6687 0.00012207 68.5001C0.00012207 106.332 30.6687 137 68.5001 137C106.332 137 137 106.332 137 68.5001C136.957 30.6866 106.314 0.0433939 68.5001 9.15527e-05V9.15527e-05ZM102.751 83.1781C102.737 93.9828 93.9829 102.737 83.1797 102.749V107.643C83.1797 110.345 80.9877 112.536 78.2865 112.536C75.5838 112.536 73.3933 110.345 73.3933 107.643V102.749H63.6084V107.643C63.6084 110.345 61.4164 112.536 58.7153 112.536C56.0126 112.536 53.8221 110.345 53.8221 107.643V102.749H39.144C36.4414 102.749 34.2509 100.559 34.2509 97.8577C34.2509 95.155 36.4414 92.9645 39.144 92.9645H44.0357V44.0357H39.144C36.4414 44.0357 34.2509 41.8452 34.2509 39.1425C34.2509 36.4399 36.4414 34.2493 39.144 34.2493H53.8221V29.3577C53.8221 26.655 56.0126 24.4645 58.7153 24.4645C61.4179 24.4645 63.6084 26.655 63.6084 29.3577V34.2493H73.3933V29.3577C73.3933 26.655 75.5838 24.4645 78.2865 24.4645C80.9891 24.4645 83.1797 26.655 83.1797 29.3577V34.2493C93.9426 34.2045 102.705 42.8919 102.751 53.6548C102.775 59.3543 100.304 64.7791 95.9867 68.5001C100.263 72.1793 102.731 77.5354 102.751 83.1781V83.1781Z"
                              fill="#FFF"
                            />
                          </svg>
                        ) : item.id === 2 ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="50px"
                            height="50px"
                          >
                            <path
                              fillRule="evenodd"
                              fill="rgb(255, 255, 255)"
                              d="M21.000,42.000 C9.402,42.000 -0.001,32.598 -0.001,21.000 C-0.001,9.402 9.402,-0.000 21.000,-0.000 C32.592,0.013 41.987,9.408 42.000,21.000 C42.000,32.598 32.598,42.000 21.000,42.000 ZM28.171,14.437 C28.383,14.172 28.499,13.843 28.499,13.504 C28.500,12.675 27.829,12.001 26.999,12.000 L22.499,12.000 L22.499,7.500 C22.499,6.672 21.828,6.000 21.000,6.000 C20.171,6.000 19.500,6.671 19.500,7.500 L19.500,12.000 L15.000,12.000 C14.171,12.000 13.499,12.671 13.499,13.500 C13.499,14.328 14.171,15.000 15.000,15.000 L23.878,15.000 L13.827,27.562 C13.615,27.829 13.499,28.160 13.499,28.501 C13.499,29.329 14.171,30.000 15.000,30.000 L19.500,30.000 L19.500,34.500 C19.500,35.328 20.171,36.000 21.000,36.000 C21.828,36.000 22.499,35.328 22.499,34.500 L22.499,30.000 L26.999,30.000 C27.828,30.000 28.500,29.328 28.500,28.500 C28.500,27.672 27.828,27.000 26.999,27.000 L18.121,27.000 L28.171,14.437 Z"
                            />
                          </svg>
                        ) : item.id === 3 ? (
                          <svg
                            width="50"
                            height="50"
                            viewBox="0 0 137 137"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M70.3615 78.5206C69.1671 78.9977 67.8366 78.9977 66.6421 78.5206L53.8232 73.3927L68.5018 102.75L83.1804 73.3927L70.3615 78.5206Z"
                              fill="#FFF"
                            />
                            <path
                              d="M68.4982 68.5L88.0696 61.6503L68.4982 34.25L48.9268 61.6503L68.4982 68.5Z"
                              fill="#FFF"
                            />
                            <path
                              d="M68.5 0C30.6686 0 0 30.6686 0 68.5C0 106.331 30.6686 137 68.5 137C106.331 137 137 106.331 137 68.5C136.958 30.6865 106.313 0.0418093 68.5 0V0ZM97.3409 65.7958L72.8765 114.725C71.6685 117.142 68.7285 118.122 66.3125 116.914C65.3643 116.44 64.5968 115.673 64.1235 114.725L39.6591 65.7958C38.899 64.2698 38.9856 62.4586 39.8875 61.0117L64.3519 21.8692C65.978 19.5787 69.151 19.0381 71.4416 20.6642C71.9089 20.9957 72.3166 21.4019 72.6481 21.8692L97.111 61.0117C98.0144 62.4586 98.101 64.2698 97.3409 65.7958V65.7958Z"
                              fill="#FFF"
                            />
                          </svg>
                        ) : item.id === 4 ? (
                          <svg
                            width="50"
                            height="45"
                            viewBox="0 0 137 137"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M68.5 0C30.6686 0 0 30.6686 0 68.5C0 106.331 30.6686 137 68.5 137C106.331 137 137 106.331 137 68.5C136.958 30.6865 106.313 0.0418093 68.5 0ZM40.213 63.6068H59.7843C62.4869 63.6068 64.6774 65.7973 64.6774 68.5C64.6774 71.2027 62.4869 73.3932 59.7843 73.3932H40.213C37.5104 73.3932 35.3199 71.2027 35.3199 68.5C35.3199 65.7973 37.5119 63.6068 40.213 63.6068ZM101.393 56.6456L95.5088 86.0883C94.1231 92.9226 88.122 97.8411 81.1488 97.8576H40.213C37.5104 97.8576 35.3199 95.6671 35.3199 92.9644C35.3199 90.2617 37.5119 88.0712 40.213 88.0712H81.1488C83.4617 88.0652 85.4522 86.4347 85.9121 84.168L91.7982 54.7253C92.3208 52.0973 90.6156 49.544 87.9891 49.0214C87.677 48.9601 87.3605 48.9288 87.0439 48.9288H49.9994C47.2967 48.9288 45.1062 46.7383 45.1062 44.0356C45.1062 41.3329 47.2967 39.1424 49.9994 39.1424H87.0439C95.128 39.1454 101.679 45.699 101.677 53.7831C101.677 54.7433 101.582 55.7019 101.393 56.6456Z"
                              fill="#FFF"
                            ></path>
                          </svg>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="card-body p-0 custome-tooltip">
                        {/* <div id="widgetChart3" className="chart-primary"></div> */}
                        <WidgetChartIndex3 title={item.name} data={item.data} />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div ref={chartRef} className="col-xl-12">
              <div className="col-xxl-9 col-lg-12">
                <div className="row">
                  <div className="col-xxl-8 col-xl-12">
                    <div className="card">
                      <div className="card-header">
                        <h4 className="heading mb-0">ETH/BTC</h4>
                        <ul className="box-controls pull-right">
                          <li>
                            <Link
                              to={"#"}
                              className="box-btn-fullscreen"
                            ></Link>
                          </li>
                        </ul>
                      </div>
                      <div className="card-body">
                        {/* <div id="coin-chart"></div> */}
                        <Index5CoinChart
                          pair={barchartData.pair}
                          data={barchartData.data}
                        />{" "}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {configured && (
              <div className="col-xl-12">
                <div className="card">
                  <Tab.Container defaultActiveKey="All">
                    <div className="card-header border-0 pb-2 flex-wrap">
                      <h4 className="heading">Trade History</h4>
                      <>
                        <Nav className="order nav nav-tabs">
                          <Nav.Link as="button" eventKey="All" type="button">
                            All Orders
                          </Nav.Link>
                          <Nav.Link as="button" eventKey="Order" type="button">
                            Open Positions
                          </Nav.Link>
                          <Nav.Link as="button" eventKey="Trade" type="button">
                            Trade Histroy
                          </Nav.Link>
                        </Nav>
                      </>
                    </div>
                    <div className="card-body pt-0 pb-0">
                      <Tab.Content>
                        <Tab.Pane eventKey="All">
                          <div className="table-responsive dataTabletrade ">
                            <div
                              id="status_wrapper"
                              className="dataTables_wrapper no-footer"
                            >
                              <table
                                id="example"
                                className="table display dataTable no-footer"
                                style={{ minWidth: "845px" }}
                              >
                                <thead>
                                  <tr>
                                    <th>Pair</th>
                                    <th>Side</th>
                                    <th>Size</th>
                                    <th>Current Price</th>
                                    <th>Entry Point</th>
                                    <th className="text-end">PnL</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {getCurrentPageData().map((item, index) => (
                                    <tr key={index}>
                                      <td>{item.pair}</td>
                                      <td>{item.side}</td>
                                      <td>{item.size}</td>
                                      <td>{item.price}</td>
                                      <td>{item.entry}</td>
                                      <td className="text-end">{item.pnl}</td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                              <div className="d-sm-flex text-center justify-content-between align-items-center mt-3 mb-3">
                                <div className="dataTables_info">
                                  Showing {(currentPage - 1) * itemsPerPage + 1}{" "}
                                  to{" "}
                                  {Math.min(
                                    currentPage * itemsPerPage,
                                    totalItems
                                  )}{" "}
                                  of {totalItems} entries
                                </div>
                                <div
                                  className="dataTables_paginate paging_simple_numbers mb-0"
                                  id="application-tbl1_paginate"
                                >
                                  <Link
                                    className="paginate_button previous "
                                    to="/trade-as-you-go"
                                    onClick={() => goToPage(currentPage - 1)}
                                    disabled={currentPage === 1}
                                  >
                                    <i className="fa fa-angle-double-left"></i>
                                  </Link>
                                  <span>
                                    {Array.from(
                                      { length: totalPages },
                                      (_, i) => i + 1
                                    ).map((number) => (
                                      <Link
                                        key={number}
                                        to="#"
                                        className={`paginate_button ${
                                          currentPage === number
                                            ? "current"
                                            : ""
                                        }`}
                                        onClick={() => goToPage(number)}
                                      >
                                        {number}
                                      </Link>
                                    ))}
                                  </span>

                                  <Link
                                    className="paginate_button next"
                                    to="/trade-as-you-go"
                                    onClick={() => goToPage(currentPage + 1)}
                                    disabled={currentPage === totalPages}
                                  >
                                    <i className="fa fa-angle-double-right"></i>
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="Order">
                          <OrderTab
                            tabDataBlog={tabeData.filter(
                              (item) => item.status === "open"
                            )}
                          />
                        </Tab.Pane>
                        <Tab.Pane eventKey="Trade">
                          <TradeTab
                            tabDataBlog={tabeData.filter(
                              (item) => item.status === "close"
                            )}
                          />
                        </Tab.Pane>
                      </Tab.Content>
                    </div>
                  </Tab.Container>
                </div>
              </div>
            )}
          </>
        )}
      </div>

      {/* <!-- Modal --> */}
      <Modal className="fade" show={modalCentered}>
        <Modal.Header>
          <Modal.Title>Allow PlowBot to Place Trade on Your Behalf</Modal.Title>
          <Button
            onClick={() => setModalCentered(false)}
            variant=""
            className="btn-close"
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <ul>
            <li className="pb-4">
              You are about to connect to finance to allow PlowGPT to trade on
              your behalf.
            </li>
            <li className="pb-4">
              Your API key and secret are not stored on the server, so your
              browser must be open always and connected to a fast internet for
              trades to execute.
            </li>
            <li className="pb-4">
              While PlowGPT has open positions, you should never transfer or
              open more positions on futures on the same account on the same or
              different pairs.
            </li>
            <li className="pb-4">
              PlowGPT "trade as you go" takes 15% of every won trade! This
              allows you to have no liability of paying upfront for the service.
            </li>
            <li className="pb-4">
              While PlowGPT is cutting edge, it indemnifies itself from any
              losses in all cases. Trading is a high-risk/high-reward activity
              and should be conducted with a very little portion of your
              portfolio.
            </li>
            <li className="pb-4">
              Accept trading terms with PlowGPT, fill in your Binance API key
              and password, then click "Connect to Binance".
            </li>
          </ul>

          <p>
            <strong>Binance API access required (permissions):</strong> futures,
            spot, withdrawals
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => setModalCentered(false)}
            variant="danger light"
          >
            Close
          </Button>
          <Button
            onClick={() => {
              setConfigure(true);
              setModalCentered(false);
            }}
            variant="primary"
          >
            Configure
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default TradeAsYouGo;
