import { lazy, Suspense, useContext, useEffect } from "react";

/// Components
import Index from "./jsx";
import { connect, useDispatch } from "react-redux";
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
// action
import { checkAutoLogin } from "./services/AuthService";
import { isAuthenticated } from "./store/selectors/AuthSelectors";
/// Style
import "./vendor/bootstrap-select/dist/css/bootstrap-select.min.css";
import "./css/style.css";
import OpenMarkup from "./jsx/open";
import { ThemeContext } from "./context/ThemeContext";

// const Home = lazy(() => import("./jsx/components/Dashboard/Home"));
// const AIParams = lazy(() => import("./jsx/components/Crypto/AIParams"));
// const Pricing = lazy(() => import("./jsx/components/Pricing/Pricing"));
// const SignUp = lazy(() => import("./jsx/pages/Registration"));
// const ForgotPassword = lazy(() => import("./jsx/pages/ForgotPassword"));
// const Login = lazy(() => {
//   return new Promise((resolve) => {
//     setTimeout(() => resolve(import("./jsx/pages/Login")), 500);
//   });
// });

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();

    return <Component {...props} router={{ location, navigate, params }} />;
  }

  return ComponentWithRouterProp;
}

function App(props) {
  // const dispatch = useDispatch();
  // const navigate = useNavigate();

  // const { changeBackground } = useContext(ThemeContext);

  // useEffect(() => {
  //   // checkAutoLogin(dispatch, navigate);
  //   changeBackground({ value: "dark", label: "Dark" });
  // }, []);

  if (props.isAuthenticated) {
    return (
      <>
        <Suspense
          fallback={
            <div id="preloader">
              <div className="sk-three-bounce">
                <div className="sk-child sk-bounce1"></div>
                <div className="sk-child sk-bounce2"></div>
                <div className="sk-child sk-bounce3"></div>
              </div>
            </div>
          }
        >
          <Index />
        </Suspense>
      </>
    );
  } else {
    return (
      <div className="vh-100">
        <Suspense
          fallback={
            <div id="preloader">
              <div className="sk-three-bounce">
                <div className="sk-child sk-bounce1"></div>
                <div className="sk-child sk-bounce2"></div>
                <div className="sk-child sk-bounce3"></div>
              </div>
            </div>
          }
        >
          <OpenMarkup />
        </Suspense>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: isAuthenticated(state),
  };
};

//export default connect((mapStateToProps)(App));
export default withRouter(connect(mapStateToProps)(App));
