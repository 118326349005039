import React, { useEffect, useRef, useState } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

am4core.useTheme(am4themes_animated);

const GaugeChart = ({ parameter }) => {
  const chartDiv = useRef(null);
  const chartRef = useRef(null); // Reference to store the chart instance
  const handRef = useRef(null); // Reference to store the hand instance

  const [data, setData] = useState({
    score: 0,
    gradingData: [
      {
        title: "Bad",
        advice:
          "Short or sell is more likely (if the higher timeframe trend is bullish don’t short)",
        color: "#E53935",
        lowScore: -100,
        highScore: -50,
      },
      {
        title: "Short",
        advice:
          "Market is in a neutral state, trading here can be significantly risky",
        color: "#FB8C00",
        lowScore: -50,
        highScore: 50,
      },
      {
        title: "OK",
        advice:
          "Long is more likely to be profitable, if the higher timeframe trend is bearish do not long.",
        color: "#43A047",
        lowScore: 50,
        highScore: 100,
      },
    ],
  });

  useEffect(() => {
    const chartMin = -100;
    const chartMax = 100;

    const lookUpGrade = (lookupScore, grades) => {
      for (let i = 0; i < grades.length; i++) {
        if (
          grades[i].lowScore < lookupScore &&
          grades[i].highScore >= lookupScore
        ) {
          return grades[i];
        }
      }
      return null;
    };

    // Create chart instance
    const chart = am4core.create(chartDiv.current, am4charts.GaugeChart);
    chart.hiddenState.properties.opacity = 0;
    chart.fontSize = 11;
    chart.innerRadius = am4core.percent(80);

    // Create axes
    const axis = chart.xAxes.push(new am4charts.ValueAxis());
    axis.min = chartMin;
    axis.max = chartMax;
    axis.strictMinMax = true;
    axis.renderer.radius = am4core.percent(80);
    axis.renderer.inside = true;
    axis.renderer.line.strokeOpacity = 0;
    axis.renderer.ticks.template.disabled = false;
    axis.renderer.ticks.template.strokeOpacity = 0;
    axis.renderer.ticks.template.strokeWidth = 0.5;
    axis.renderer.ticks.template.length = 5;
    axis.renderer.grid.template.disabled = true;
    axis.renderer.labels.template.radius = am4core.percent(15);
    axis.renderer.labels.template.fontSize = "0.9em";
    axis.renderer.labels.template.fill = am4core.color("#757575");

    // Create ranges
    const axis2 = chart.xAxes.push(new am4charts.ValueAxis());
    axis2.min = chartMin;
    axis2.max = chartMax;
    axis2.renderer.radius = am4core.percent(105);
    axis2.strictMinMax = true;
    axis2.renderer.labels.template.disabled = true;
    axis2.renderer.ticks.template.disabled = true;
    axis2.renderer.grid.template.disabled = false;
    axis2.renderer.grid.template.opacity = 0;
    axis2.renderer.labels.template.bent = true;
    axis2.renderer.labels.template.fill = am4core.color("#000");
    axis2.renderer.labels.template.fontWeight = "bold";
    axis2.renderer.labels.template.fillOpacity = 0;
    axis2.numberFormatter.numberFormat = "'B '#a";

    for (let grading of data.gradingData) {
      const range = axis2.axisRanges.create();
      range.axisFill.fill = am4core.color(grading.color);
      range.axisFill.fillOpacity = 1;
      range.axisFill.zIndex = -1;
      range.value = grading.lowScore > chartMin ? grading.lowScore : chartMin;
      range.endValue =
        grading.highScore < chartMax ? grading.highScore : chartMax;
      range.grid.strokeOpacity = 0;
      range.stroke = am4core.color(grading.color).lighten(-0.1);
      range.label.inside = true;
      range.label.text = grading.title.toUpperCase();
      range.label.location = 0.5;
      range.label.radius = am4core.percent(10);
      range.label.paddingBottom = -5;
      range.label.fontSize = "0.9em";
    }

    // Create hand
    const hand = chart.hands.push(new am4charts.ClockHand());
    hand.axis = axis2;
    hand.innerRadius = am4core.percent(50);
    hand.startWidth = 10;
    hand.pin.disabled = true;
    hand.value = data.score;
    hand.fill = am4core.color("#444");
    hand.stroke = am4core.color("#000");

    // Create texts
    const topContainer = chart.chartContainer.createChild(am4core.Container);
    topContainer.layout = "absolute";
    topContainer.toBack();
    topContainer.width = am4core.percent(100);

    // GRADE
    const leftTopContainer = topContainer.createChild(am4core.Container);
    leftTopContainer.layout = "vertical";
    leftTopContainer.align = "left";

    const gradeLabel = leftTopContainer.createChild(am4core.Label);
    gradeLabel.text = "Forecast";
    gradeLabel.fill = am4core.color("#757575");
    gradeLabel.fontSize = "18px";
    gradeLabel.fontWeight = 500;
    gradeLabel.align = "left";

    const gradeValue = leftTopContainer.createChild(am4core.Label);
    gradeValue.text = "Short";
    gradeValue.tooltipText = "Score is calculated from large state AI metrics.";
    gradeValue.tooltip.pointerOrientation = "left";
    gradeValue.tooltip.dx = 12;
    gradeValue.fontSize = "18px";
    gradeValue.fontWeight = 500;
    gradeValue.align = "left";
    gradeValue.background = new am4core.RoundedRectangle();
    gradeValue.background.cornerRadius(4, 4, 4, 4);
    gradeValue.padding(8, 12, 8, 12);
    gradeValue.background.fill = am4core.color("#E53935");

    // TARGET
    const rightTopContainer = topContainer.createChild(am4core.Container);
    rightTopContainer.layout = "vertical";
    rightTopContainer.align = "right";

    const targetLabel = rightTopContainer.createChild(am4core.Label);
    targetLabel.text = "Forecast";
    targetLabel.fill = am4core.color("#757575");
    targetLabel.fontSize = "18px";
    targetLabel.fontWeight = 500;
    targetLabel.align = "right";

    const targetValue = rightTopContainer.createChild(am4core.Label);
    targetValue.text = "Long";
    targetValue.fontSize = "18px";
    targetValue.fontWeight = 500;
    targetValue.align = "right";
    targetValue.background = new am4core.RoundedRectangle();
    targetValue.background.cornerRadius(4, 4, 4, 4);
    targetValue.padding(8, 12, 8, 12);
    targetValue.background.fill = am4core.color("#B0CE55");

    // Metric Value
    const labelMetricValue = chart.radarContainer.createChild(am4core.Label);
    labelMetricValue.isMeasured = false;
    labelMetricValue.fontSize = "3em";
    labelMetricValue.x = am4core.percent(50);
    labelMetricValue.paddingBottom = 15;
    labelMetricValue.horizontalCenter = "middle";
    labelMetricValue.verticalCenter = "bottom";
    labelMetricValue.text = data.score.toFixed(1);
    labelMetricValue.fill = am4core.color("#757575");

    // Advice
    const labelAdvice = chart.createChild(am4core.Label);
    labelAdvice.isMeasured = false;
    labelAdvice.fontSize = "1em";
    labelAdvice.horizontalCenter = "middle";
    labelAdvice.verticalCenter = "bottom";
    labelAdvice.text =
      "Market is in a neutral state, trading here can be significantly risky";
    labelAdvice.fill = am4core.color("#757575");
    labelAdvice.dx = 280;
    labelAdvice.dy = 340;

    hand.events.on("positionchanged", () => {
      const value = axis2.positionToValue(hand.currentPosition);
      const matchingGrade = lookUpGrade(value, data.gradingData);
      if (matchingGrade) {
        gradeValue.text = matchingGrade.title;
        gradeValue.background.fill = am4core.color(matchingGrade.color);
        labelMetricValue.text = value.toFixed(1);
        labelMetricValue.fill = am4core.color(matchingGrade.color);
        labelAdvice.text = matchingGrade.advice.toUpperCase();
        labelAdvice.fill = am4core.color(matchingGrade.color);
      }
    });

    chartRef.current = chart; // Store the chart instance in the ref
    handRef.current = hand; // Store the hand instance in the ref

    // Clean up chart when component unmounts
    return () => {
      chart.dispose();
    };
  }, [data.gradingData]);

  useEffect(() => {
    // Function to map the parameter to the -100 to 100 range
    const mapToRange = (param) => {
      // Ensure the input number is between 0 and 1
      param = Math.min(1, Math.max(0, param));

      // Map the input number to the desired range (-100 to 100)
      return (1 - param) * 200 - 100;
    };

    // Update the data.score based on the parameter
    const newScore = mapToRange(parameter);
    setData((prevData) => ({
      ...prevData,
      score: -newScore,
    }));

    // Update the chart hand value if the chart is created
    if (chartRef.current && handRef.current) {
      handRef.current.showValue(newScore, 1000, am4core.ease.cubicOut);
    }
  }, [parameter]);

  return <div id="chartdiv" ref={chartDiv} style={{ height: "375px" }} />;
};

export default GaugeChart;
