import React from "react";
import { Link } from "react-router-dom";

const ApiAccess = () => {
  return (
    <>
      <div className="row justify-content-center h-100 align-items-center ">
        <div className="col-md-7">
          <div className="form-input-content text-center error-page">
            <h1 className="error-text fw-bold">Coming Soon</h1>
            <h4>
              <i className="fa fa-exclamation-triangle text-warning" /> This
              feature is not yet available!
            </h4>
            <p>Be on the lookout for announcements on our official handles</p>
            <div>
              <Link className="btn btn-primary" to="/">
                Back to Home
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ApiAccess;
