import React, { useState } from "react";
import { Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";

const MsgBox = ({ avatar1, avatar2, openMsg, PerfectScrollbar, offMsg }) => {
  const [toggle, setToggle] = useState(false);

  const handleButtonClick = () => {
    // Show a toast notification
    toast.info(`Feature will be available in plow-v2`, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  return (
    <div
      className={`card chat dlab-chat-history-box ${openMsg ? "" : "d-none"}`}
    >
      <ToastContainer />

      <div className="card-header chat-list-header text-center">
        <div>
          <h6 className="mb-1">Chat with PlowBot</h6>
          <p className="mb-0 text-success">Online</p>
        </div>
      </div>
      <PerfectScrollbar
        className={`card-body msg_card_body dlab-scroll ${
          openMsg ? "ps ps--active-y" : ""
        } `}
        id="DZ_W_Contacts_Body3"
        style={{ height: "calc(100vh - 195px)" }}
      >
        {/* <div className="d-flex justify-content-start mb-4">
          <div className="img_cont_msg">
            <img src={avatar1} className="rounded-circle user_img_msg" alt="" />
          </div>
          <div className="msg_cotainer">
            Hi, how are you samim?
            <span className="msg_time">8:40 AM, Today</span>
          </div>
        </div>
        <div className="d-flex justify-content-end mb-4">
          <div className="msg_cotainer_send">
            Hi Khalid i am good tnx how about you?
            <span className="msg_time_send">8:55 AM, Today</span>
          </div>
          <div className="img_cont_msg">
            <img src={avatar2} className="rounded-circle user_img_msg" alt="" />
          </div>
        </div>
        <div className="d-flex justify-content-start mb-4">
          <div className="img_cont_msg">
            <img src={avatar1} className="rounded-circle user_img_msg" alt="" />
          </div>
          <div className="msg_cotainer">
            I am good too, thank you for your chat template
            <span className="msg_time">9:00 AM, Today</span>
          </div>
        </div>
        <div className="d-flex justify-content-end mb-4">
          <div className="msg_cotainer_send">
            You are welcome
            <span className="msg_time_send">9:05 AM, Today</span>
          </div>
          <div className="img_cont_msg">
            <img src={avatar2} className="rounded-circle user_img_msg" alt="" />
          </div>
        </div>
        <div className="d-flex justify-content-start mb-4">
          <div className="img_cont_msg">
            <img src={avatar1} className="rounded-circle user_img_msg" alt="" />
          </div>
          <div className="msg_cotainer">
            I am looking for your next templates
            <span className="msg_time">9:07 AM, Today</span>
          </div>
        </div>
        <div className="d-flex justify-content-end mb-4">
          <div className="msg_cotainer_send">
            Ok, thank you have a good day
            <span className="msg_time_send">9:10 AM, Today</span>
          </div>
          <div className="img_cont_msg">
            <img src={avatar2} className="rounded-circle user_img_msg" alt="" />
          </div>
        </div>
        <div className="d-flex justify-content-start mb-4">
          <div className="img_cont_msg">
            <img src={avatar1} className="rounded-circle user_img_msg" alt="" />
          </div>
          <div className="msg_cotainer">
            Bye, see you
            <span className="msg_time">9:12 AM, Today</span>
          </div>
        </div>
        <div className="d-flex justify-content-start mb-4">
          <div className="img_cont_msg">
            <img src={avatar1} className="rounded-circle user_img_msg" alt="" />
          </div>
          <div className="msg_cotainer">
            Hi, how are you samim?
            <span className="msg_time">8:40 AM, Today</span>
          </div>
        </div>
        <div className="d-flex justify-content-end mb-4">
          <div className="msg_cotainer_send">
            Hi Khalid i am good tnx how about you?
            <span className="msg_time_send">8:55 AM, Today</span>
          </div>
          <div className="img_cont_msg">
            <img src={avatar2} className="rounded-circle user_img_msg" alt="" />
          </div>
        </div>
        <div className="d-flex justify-content-start mb-4">
          <div className="img_cont_msg">
            <img src={avatar1} className="rounded-circle user_img_msg" alt="" />
          </div>
          <div className="msg_cotainer">
            I am good too, thank you for your chat template
            <span className="msg_time">9:00 AM, Today</span>
          </div>
        </div>
        <div className="d-flex justify-content-end mb-4">
          <div className="msg_cotainer_send">
            You are welcome
            <span className="msg_time_send">9:05 AM, Today</span>
          </div>
          <div className="img_cont_msg">
            <img src={avatar2} className="rounded-circle user_img_msg" alt="" />
          </div>
        </div>
        <div className="d-flex justify-content-start mb-4">
          <div className="img_cont_msg">
            <img src={avatar1} className="rounded-circle user_img_msg" alt="" />
          </div>
          <div className="msg_cotainer">
            I am looking for your next templates
            <span className="msg_time">9:07 AM, Today</span>
          </div>
        </div>
        <div className="d-flex justify-content-end mb-4">
          <div className="msg_cotainer_send">
            Ok, thank you have a good day
            <span className="msg_time_send">9:10 AM, Today</span>
          </div>
          <div className="img_cont_msg">
            <img src={avatar2} className="rounded-circle user_img_msg" alt="" />
          </div>
        </div>
        <div className="d-flex justify-content-start mb-4">
          <div className="img_cont_msg">
            <img src={avatar1} className="rounded-circle user_img_msg" alt="" />
          </div>
          <div className="msg_cotainer">
            Bye, see you
            <span className="msg_time">9:12 AM, Today</span>
          </div>
        </div> */}
      </PerfectScrollbar>
      <div className="card-footer type_msg">
        <div className="input-group">
          <textarea
            className="form-control"
            placeholder="Type your message..."
          ></textarea>
          <div onClick={handleButtonClick} className="input-group-append">
            <button type="button" className="btn btn-primary">
              <i className="fa fa-location-arrow"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MsgBox;
