import React from "react";
import { Link } from "react-router-dom";

import PriceBlog from "./PriceBlog";

// import Shap1 from "./../images/shape1.png";
// import Shap3 from "./../images/shape3.png";

const Pricing = () => {
  return (
    <>
      <div className="row justify-content-center h-100 align-items-center ">
        <section className="content-inner pricing-plan-wrapper bg-primary-light">
          {/* <img className="bg-shape2" src={Shap1} alt="" />
          <img className="bg-shape3" src={Shap1} alt="" />
          <img className="bg-shape1" src={Shap3} alt="" />
          <img className="bg-shape4" src={Shap3} alt="" />
          <img className="bg-shape5" src={Shap3} alt="" /> */}
          <div className="container">
            <div className="section-head text-center pb-5">
              <h2 className="title">
                Awesome Pricing Plan for Cryptocurrency Business
              </h2>
            </div>
            <div className="row justify-content-center">
              <PriceBlog />
            </div>
          </div>
        </section>
        {/* <div className="col-md-7">
          <div className="form-input-content text-center error-page">
            <h1 className="error-text fw-bold">Coming Soon</h1>
            <h4>
              <i className="fa fa-exclamation-triangle text-warning" /> The page
              you were looking for is not yet available!
            </h4>
            <p>Be on the lookout for announcements on our official handles</p>
            <div>
              <Link className="btn btn-primary" to="/">
                Back to Home
              </Link>
            </div>
          </div>
        </div> */}
      </div>
    </>
  );
};
export default Pricing;
