import React, { useContext } from "react";

/// React router dom
import { Routes, Route, Outlet } from "react-router-dom";

/// Css
import "./index.css";
import "./chart.css";
import "./step.css";

/// Layout
import Nav from "./layouts/nav";
import Footer from "./layouts/Footer";
//import Main from './layouts/Main';

import ScrollToTop from "./layouts/ScrollToTop";

import LockScreen from "./pages/LockScreen";
import Error400 from "./pages/Error400";
import Error403 from "./pages/Error403";
import Error404 from "./pages/Error404";
import Error500 from "./pages/Error500";
import Error503 from "./pages/Error503";
import { ThemeContext } from "../context/ThemeContext";

import ApiAccess from "./components/Dashboard/ApiAccess";
import Pricing from "./components/Pricing/Pricing";
import TradeAsYouGo from "./components/Trading/TradeAsYouGo";
import AIParams from "./components/Crypto/AIParams";
import Home from "./components/Dashboard/Home";
import Login from "./pages/Login";
import Registration from "./pages/Registration";
import ForgotPassword from "./pages/ForgotPassword";
import ResetModal from "./components/Dashboard/ResetModal";

const OpenMarkup = () => {
  const allroutes = [
    /// Dashboard
    { url: "/", component: <Home /> },
    { url: "params", component: <AIParams /> },
    { url: "pricing", component: <Pricing /> },
  ];

  return (
    <>
      <Routes>
        <Route element={<MainLayout />}>
          {allroutes.map((data, i) => (
            <Route
              key={i}
              exact
              path={`${data.url}`}
              element={data.component}
            />
          ))}
        </Route>
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Registration />} />
        <Route path="/page-forgot-password" element={<ForgotPassword />} />
      </Routes>
      <ScrollToTop />
    </>
  );
};

function MainLayout() {
  const { menuToggle } = useContext(ThemeContext);
  // Check if the current route is 'pricing'
  const isPricingRoute = window.location.pathname.includes("/pricing");

  return (
    <div
      id="main-wrapper"
      className={`show ${menuToggle ? "menu-toggle" : ""}`}
    >
      <Nav />
      <div
        className="content-body"
        style={{ minHeight: window.screen.height - 45 }}
      >
        <div className="container-fluid">
          <Outlet />
        </div>
      </div>
      <Footer />
      {!isPricingRoute && <ResetModal />}{" "}
    </div>
  );
}

export default OpenMarkup;
