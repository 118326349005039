import React, { useState, useEffect } from "react";

const CountdownToNextMinute = () => {
  const [timeLeft, setTimeLeft] = useState(60 - new Date().getSeconds());

  useEffect(() => {
    // Update the countdown every second
    const interval = setInterval(() => {
      const seconds = new Date().getSeconds();
      setTimeLeft(60 - seconds);
    }, 1000);

    // Cleanup interval on component unmount
    return () => clearInterval(interval);
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignContent: "center",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <h4>Countdown to the next update</h4>
      <h1>{timeLeft} seconds</h1>
    </div>
  );
};

export default CountdownToNextMinute;
