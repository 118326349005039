import React, { useState, useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Link } from "react-router-dom";
/// Scroll
//import PerfectScrollbar from "react-perfect-scrollbar";

/// Image
//import profile from "../../../images/profile/pic1.jpg";
//import avatar from "../../../images/avatar/1.jpg";
//import { Dropdown } from "react-bootstrap";
//import LogoutPage from './Logout';
//import RightSideBar from './RightSideBar';

import LogoutPage from "./Logout";

import United from "../../../images/United.png";
import avatar from "../../../images/avatar/1.jpg";
import profile from "../../../images/profile/pic1.jpg";
import { isAuthenticated } from "../../../store/selectors/AuthSelectors";
import { connect } from "react-redux";

const NotificationBlog = ({ classChange }) => {
  return (
    <>
      <li>
        <div className="timeline-panel">
          <div className="media me-2">
            <img alt="images" width={50} src={avatar} />
          </div>
          <div className="media-body">
            <h6 className="mb-1">Dr sultads Send you Photo</h6>
            <small className="d-block">29 July 2022 - 02:26 PM</small>
          </div>
        </div>
      </li>
      <li>
        <div className="timeline-panel">
          <div className={`media me-2 ${classChange}`}>KG</div>
          <div className="media-body">
            <h6 className="mb-1">Resport created successfully</h6>
            <small className="d-block">29 July 2022 - 02:26 PM</small>
          </div>
        </div>
      </li>
      <li>
        <div className="timeline-panel">
          <div className={`media me-2 ${classChange}`}>
            <i className="fa fa-home" />
          </div>
          <div className="media-body">
            <h6 className="mb-1">Reminder : Treatment Time!</h6>
            <small className="d-block">29 July 2022 - 02:26 PM</small>
          </div>
        </div>
      </li>
    </>
  );
};

const Header = ({ onNote, isAuthenticated }) => {
  const [rightSelect, setRightSelect] = useState("Eng");
  //For fix header
  const [headerFix, setheaderFix] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setheaderFix(window.scrollY > 50);
    });
  }, []);

  //const [searchBut, setSearchBut] = useState(false);
  var path = window.location.pathname.split("/");
  var name = path[path.length - 1].split("-");
  var filterName = name.length >= 3 ? name.filter((n, i) => i > 0) : name;
  var finalName = filterName.includes("app")
    ? filterName.filter((f) => f !== "app")
    : filterName.includes("ui")
    ? filterName.filter((f) => f !== "ui")
    : filterName.includes("uc")
    ? filterName.filter((f) => f !== "uc")
    : filterName.includes("basic")
    ? filterName.filter((f) => f !== "basic")
    : filterName.includes("jquery")
    ? filterName.filter((f) => f !== "jquery")
    : filterName.includes("table")
    ? filterName.filter((f) => f !== "table")
    : filterName.includes("page")
    ? filterName.filter((f) => f !== "page")
    : filterName.includes("email")
    ? filterName.filter((f) => f !== "email")
    : filterName.includes("ecom")
    ? filterName.filter((f) => f !== "ecom")
    : filterName.includes("chart")
    ? filterName.filter((f) => f !== "chart")
    : filterName.includes("editor")
    ? filterName.filter((f) => f !== "editor")
    : filterName;
  return (
    <div className={`header ${headerFix ? "is-fixed" : ""}`}>
      <div className="header-content">
        <nav className="navbar navbar-expand">
          <div className="collapse navbar-collapse justify-content-between">
            <div className="header-left">
              <div
                className="dashboard_bar"
                style={{ textTransform: "capitalize" }}
              >
                {finalName.join(" ").length === 0
                  ? "Dashboard"
                  : finalName.join(" ") === "dashboard dark"
                  ? "Dashboard"
                  : finalName.join(" ")}
              </div>
            </div>
            <div className="navbar-nav header-right">
              <div className="nav-item d-flex align-items-center">
                <div className="input-group search-area">
                  <span className="input-group-text">
                    <Link to={"#"}>
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 32 32"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M27.414 24.586L22.337 19.509C23.386 17.928 24 16.035 24 14C24 8.486 19.514 4 14 4C8.486 4 4 8.486 4 14C4 19.514 8.486 24 14 24C16.035 24 17.928 23.386 19.509 22.337L24.586 27.414C25.366 28.195 26.634 28.195 27.414 27.414C28.195 26.633 28.195 25.367 27.414 24.586ZM7 14C7 10.14 10.14 7 14 7C17.86 7 21 10.14 21 14C21 17.86 17.86 21 14 21C10.14 21 7 17.86 7 14Z"
                          fill="var(--secondary)"
                        />
                      </svg>
                    </Link>
                  </span>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search here..."
                  />
                </div>
              </div>
              <div className="dz-side-menu">
                <div className="search-coundry d-flex align-items-center">
                  <img src={United} alt="" className="mx-2" />
                  <Dropdown className="sidebar-dropdown me-2 mt-2">
                    <Dropdown.Toggle
                      as="div"
                      className="i-false sidebar-select"
                    >
                      {rightSelect}{" "}
                      <i className="fa-solid fa-angle-down ms-2" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item onClick={() => setRightSelect("Eng")}>
                        Eng
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => setRightSelect("Af")}>
                        Af
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => setRightSelect("Al")}>
                        Al
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <div className="sidebar-social-link ">
                  <ul className="">
                    <Dropdown
                      as="li"
                      className="nav-item dropdown notification_dropdown "
                    >
                      <Dropdown.Toggle
                        variant=""
                        as="a"
                        className="nav-link  i-false c-pointer"
                        onClick={() => onNote()}
                      >
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M16.9394 3.57129C18.2804 3.57129 19.5704 4.06765 20.5194 4.95828C21.4694 5.84704 22.0004 7.04579 22.0004 8.30073V15.6993C22.0004 18.3122 19.7304 20.4287 16.9394 20.4287H7.0604C4.2694 20.4287 2.0004 18.3122 2.0004 15.6993V8.30073C2.0004 5.68783 4.2594 3.57129 7.0604 3.57129H16.9394ZM18.5304 9.69615L18.6104 9.62123C18.8494 9.34964 18.8494 8.9563 18.5994 8.68471C18.4604 8.54517 18.2694 8.45994 18.0704 8.44121C17.8604 8.43091 17.6604 8.4974 17.5094 8.62852L13.0004 12C12.4204 12.4505 11.5894 12.4505 11.0004 12L6.5004 8.62852C6.1894 8.41312 5.7594 8.44121 5.5004 8.69407C5.2304 8.94693 5.2004 9.34964 5.4294 9.6306L5.5604 9.75234L10.1104 13.077C10.6704 13.4891 11.3494 13.7138 12.0604 13.7138C12.7694 13.7138 13.4604 13.4891 14.0194 13.077L18.5304 9.69615Z"
                            fill="#130F26"
                          />
                        </svg>
                      </Dropdown.Toggle>
                    </Dropdown>
                  </ul>
                </div>
                <ul>
                  <Dropdown
                    as="li"
                    className="nav-item dropdown header-profile"
                  >
                    <Dropdown.Toggle
                      variant=""
                      as="a"
                      className="nav-link i-false c-pointer"
                    >
                      <img src={profile} width={20} alt="" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu
                      align="right"
                      className="dropdown-menu dropdown-menu-end"
                    >
                      {isAuthenticated ? (
                        <LogoutPage />
                      ) : (
                        <>
                          <Link to="/login" className="dropdown-item ai-icon">
                            <svg
                              id="icon-user1"
                              xmlns="http://www.w3.org/2000/svg"
                              className="text-primary me-1"
                              width={18}
                              height={18}
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth={2}
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            >
                              <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
                              <circle cx={12} cy={7} r={4} />
                            </svg>
                            <span className="ms-2">Login </span>
                          </Link>
                          <Link
                            to="/register"
                            className="dropdown-item ai-icon"
                          >
                            <svg
                              id="icon-user1"
                              xmlns="http://www.w3.org/2000/svg"
                              className="text-primary me-1"
                              width={18}
                              height={18}
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth={2}
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            >
                              <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
                              <circle cx={12} cy={7} r={4} />
                            </svg>
                            <span className="ms-2">Register </span>
                          </Link>
                        </>
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                </ul>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: isAuthenticated(state),
  };
};

export default connect(mapStateToProps)(Header);
