import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";

const Index5CoinChart = ({ pair, data }) => {
  const [series, setSeries] = useState([
    {
      name: pair,
      data: data,
    },
  ]);

  const [options, setOptions] = useState({
    chart: {
      type: "candlestick",
      height: 400,
    },
    title: {
      text: `CandleStick Chart for ${pair}`,
      align: "left",
    },
    xaxis: {
      type: "datetime",
    },
    yaxis: {
      tooltip: {
        enabled: true,
      },
    },
  });

  useEffect(() => {
    setSeries([{ name: pair, data: data }]);
  }, [pair, data]);

  return (
    <div id="coin-chart">
      <ReactApexChart
        options={options}
        series={series}
        type="candlestick"
        height={400}
      />
    </div>
  );
};

export default Index5CoinChart;
